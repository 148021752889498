import React from 'react';

import { Container, Row } from '../../grid';

import styles from './hero.module.scss';

import { ReactComponent as HeroTextBg } from '../../assets/img/bg-home-hero.svg';
import { SvgBackground } from '../../visual';
import { Animate } from '../../react-gsap';
import { useInView } from 'react-intersection-observer';

const setInitialState = ref => {
    ref.querySelector('.bg').setAttribute('x', '-100%');
    ref.querySelector('.line').style.strokeDasharray = 3000;
    ref.querySelector('.line').style.strokeDashoffset = 3000;
};

const animateItems = (ref, tween) => {
    const bg = ref.querySelector('.bg');
    const line = ref.querySelector('.line');
    tween.to(bg, 1.0, { attr: { x: '0%' } });
    tween.to(line, 1.5, { strokeDashoffset: 0 });
};

const SectionHero = () => {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <section className={styles.hero}>
            <Container>
                <Row className={styles.row}>
                    <div className={styles.title}>
                        <h1>we brew apps</h1>
                    </div>
                    <div className={styles.image}>
                        <img
                            src={`${process.env.PUBLIC_URL}/illustrations/hero.svg`}
                            alt="we brew apps"
                        />
                    </div>
                </Row>
            </Container>

            <div ref={ref} className={styles.text}>
                <Container className={styles.container}>
                    <h2>
                        we are a team of strategists and developers who enjoy building scalable and
                        intuitive software solutions.
                    </h2>
                </Container>
                <Animate start={inView} to={animateItems} from={setInitialState}>
                    <SvgBackground svg={HeroTextBg} />
                </Animate>
            </div>
        </section>
    );
};

export default SectionHero;
