import React from 'react';

import Animate from './Animate';

const FadeIn = ({ start, timeline, duration, delay, children }) => {
    return (
        <Animate
            from={{ opacity: 0.0 }}
            to={{ opacity: 1.0 }}
            start={start}
            timeline={timeline}
            duration={duration}
            delay={delay}
            children={children}
        />
    );
};

export default FadeIn;
