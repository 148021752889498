import React from 'react';

import styles from './contact.module.scss';
import { ReactComponent as ClientBg } from '../../assets/img/bg-contact.svg';
import { Container } from '../../grid';

import { SvgBackground } from '../../visual';

import { useInView } from 'react-intersection-observer';
import ContactForm from './ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Col } from '../../grid';
import { Animate, FadeIn, useTimeline } from '../../react-gsap';

const SectionContact = () => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const timeline = useTimeline();

    return (
        <section ref={ref} className={styles.contact}>
            <Animate
                start={inView}
                to={{ attr: { r: 1440 } }}
                from={el => {
                    el.setAttribute('r', 0);
                }}
                timeline={timeline}
                duration={1.0}
                selector=".bg"
            >
                <SvgBackground svg={ClientBg} />
            </Animate>
            <FadeIn duration={1.0} timeline={timeline} start={inView}>
                <Container className={styles.formContainer}>
                    <h2>
                        let us <span className="color-moon">brew</span> your app
                        <span className="color-moon">.</span>
                    </h2>
                    <Row>
                        <Col grow={3} className={styles.illustration}>
                            <img
                                src={`${process.env.PUBLIC_URL}/illustrations/contact.svg`}
                                alt="Contact us"
                            />
                        </Col>
                        <Col grow={2}>
                            <ContactForm />
                        </Col>
                    </Row>

                    <div className={styles.info}>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon="envelope" />
                                <span>hello@appristas.io</span>
                            </li>
                        </ul>
                    </div>
                </Container>
            </FadeIn>
        </section>
    );
};

export default SectionContact;
