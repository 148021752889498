import React from 'react';

import { Hero, Services, Clients } from '../sections/home';
import { Contact } from '../sections/common';

import { AnimatedPage } from '../visual';

const PageHome = () => (
    <AnimatedPage>
        <Hero />
        <Services />
        <Clients />
        <Contact />
    </AnimatedPage>
);

export default PageHome;
