import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Row } from '../../grid';

import styles from './services.module.scss';

import { ReactComponent as IconEnterprise } from '../../assets/icons/icon-enterprise.svg';
import { ReactComponent as IconMobile } from '../../assets/icons/icon-mobile.svg';
import { ReactComponent as IconWeb } from '../../assets/icons/icon-web.svg';

import { ReactComponent as ServicesBg } from '../../assets/img/bg-home-services.svg';
import { SvgBackground } from '../../visual';

import { useInView } from 'react-intersection-observer';

import { Elastic } from 'gsap';

import { Animate, FadeIn, useTimeline } from '../../react-gsap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fromFn = el => {
    if (window.matchMedia('(min-width: 666px)').matches) {
        el.setAttribute('x', '100%');
    } else {
        el.setAttribute('x', '50%');
    }
};

const SectionServices = () => {
    const timeline = useTimeline();
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

    const services = [
        { name: 'Enterprise Software', icon: <IconEnterprise /> },
        { name: 'Mobile Development', icon: <IconMobile /> },
        { name: 'Web Development', icon: <IconWeb /> }
    ];

    return (
        <section ref={ref} className={styles.services}>
            <Animate
                start={inView}
                to={{ attr: { x: '0%' } }}
                from={fromFn}
                timeline={timeline}
                duration={1.0}
                selector=".bg"
            >
                <SvgBackground svg={ServicesBg} />
            </Animate>
            <Container>
                <FadeIn timeline={timeline} delay="-=0.1" duration={0.3} start={inView}>
                    <h2>
                        what we do
                        <span className="color-sun">?</span>
                    </h2>
                </FadeIn>

                <Row as="ul">
                    {services.map(service => (
                        <FadeIn timeline={timeline} duration={0.2} start={inView}>
                            <li key={service.name}>
                                {service.icon}
                                <h4>{service.name}</h4>
                            </li>
                        </FadeIn>
                    ))}
                </Row>
                <Animate
                    from={{ x: '100%' }}
                    to={{ x: '0%', ease: Elastic.easeOut.config(0.3, 0.3) }}
                    duration={0.8}
                    timeline={timeline}
                    start={inView}
                >
                    <div className={styles.viewMore}>
                        <Link to="/services">
                            <span className={styles.viewMoreText}>Services</span>
                            <span className={styles.viewMoreIcon}>
                                <FontAwesomeIcon icon="chevron-right" />
                            </span>
                        </Link>
                    </div>
                </Animate>
            </Container>
        </section>
    );
};

export default SectionServices;
