import React from 'react';
import { func } from 'prop-types';

import styles from './svg-bg.module.scss';

const SvgBackground = React.forwardRef(({ svg: SvgComponent }, ref) => (
    <div className={styles.background} ref={ref}>
        <SvgComponent />
    </div>
));

SvgBackground.propTypes = {
    svg: func.isRequired
};

export default SvgBackground;
