import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Row } from '../../grid';

import styles from './contact.module.scss';

import { submitForm } from './submitForm';

export default class ContactForm extends Component {
    state = {
        submitting: false,
        valid: window.navigator.userAgent === 'ReactSnap' ? true : false,
        success: false,
        name: '',
        email: '',
        company: '',
        message: '',
        errors: {}
    };

    onSubmit = async e => {
        e.preventDefault();
        const { name, email, company, message } = this.state;
        const data = { name, email, company, message };
        this.setState({ submitting: true });
        try {
            await submitForm(data);
            this.setState({ submitting: false, success: true });
        } catch (errors) {
            this.setState({ submitting: false, errors });
        }
    };

    onChange = name => e => {
        this.setState({ [name]: e.target.value });
    };

    componentDidUpdate(prevProps, prevState) {
        const { name, email, message } = this.state;

        if (prevState.name === name && prevState.email === email && prevState.message === message) {
            return;
        }

        if (
            name === '' ||
            email === '' ||
            message === '' ||
            // eslint-disable-next-line
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
            )
        ) {
            this.setState({ valid: false });
        } else {
            this.setState({ valid: true });
        }
    }

    renderError = error => {
        if (this.state.errors[error]) {
            return this.state.errors[error].map(err => <span key={err}>{err}</span>);
        }

        return null;
    };

    renderInput = (name, label, required = true, type = 'text') => (
        <div className={`${styles.formCol} ${this.state.errors[name] ? styles.error : ''}`}>
            <label htmlFor={`contact-form-${name}`}>
                {label} {required && <span className={styles.required}>*</span>}
            </label>
            <input
                id={`contact-form-${name}`}
                type={type}
                name={name}
                onChange={this.onChange(name)}
                required={required}
                value={this.state[name]}
            />
            {this.renderError(name)}
        </div>
    );

    renderTextarea = (name, label, required = true) => (
        <div className={`${styles.formCol} ${this.state.errors[name] ? styles.error : ''}`}>
            <label htmlFor={`contact-form-${name}`}>
                {label} {required && <span className={styles.required}>*</span>}
            </label>
            <textarea
                id={`contact-form-${name}`}
                name={name}
                onChange={this.onChange(name)}
                required={required}
                value={this.state[name]}
            >
                {this.state[name]}
            </textarea>
            {this.renderError(name)}
        </div>
    );

    renderButton() {
        if (this.state.submitting) {
            return <FontAwesomeIcon icon="circle-notch" spin size="lg" />;
        } else if (this.state.success) {
            return 'Thank you';
        }

        return 'Send';
    }

    render() {
        const { success, valid, submitting } = this.state;
        return (
            <form
                ref={this.formRef}
                method="post"
                action={`${process.env.REACT_APP_API_URL}/v1/contact/no-js/`}
                className={styles.form}
                onSubmit={this.onSubmit}
            >
                <Row className={styles.formRow}>
                    {this.renderInput('name', 'Name')}
                    {this.renderInput('email', 'Email', true, 'email')}
                </Row>
                <Row className={styles.formRow}>
                    {this.renderInput('company', 'Company', false)}
                </Row>
                <Row className={styles.formRow}>
                    {this.renderTextarea('message', 'Message', true)}
                </Row>
                <Row className={styles.formRow}>
                    <div className={styles.formCol}>
                        <button
                            type="submit"
                            className={success ? styles.success : ''}
                            disabled={success || !valid || submitting}
                        >
                            {this.renderButton()}
                        </button>
                    </div>
                </Row>
                <Link to="/thank-you" style={{ display: 'none' }} />
            </form>
        );
    }
}
