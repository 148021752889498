import React from 'react';

import { Container, Row } from '../../grid';

import styles from './clients.module.scss';

import { useInView } from 'react-intersection-observer';

import { FadeIn } from '../../react-gsap';

const SectionClients = () => {
    const [ref, inView] = useInView({ triggerOnce: true });

    const clients = [
        {
            logo: `/clients/noc.png`,
            name: 'National Olympic Committee of Azerbaijan Republic'
        },
        {
            logo: '/clients/aaf.png',
            name: 'Azerbaijan Athletics Federation'
        },
        {
            logo: '/clients/texno.png',
            name: 'Texno Atinak'
        },
        {
            logo: '/clients/gilan.png',
            name: 'Gilan Holding'
        },
        {
            logo: '/clients/amspaces.png',
            name: 'American Spaces'
        }
    ];
    return (
        <div ref={ref} className={styles.clients}>
            <Container>
                <h2>our experience</h2>
                <FadeIn start={inView} duration={0.5}>
                    <Row as="ul">
                        {clients.map(client => (
                            <li key={client.name}>
                                <img
                                    src={`${process.env.PUBLIC_URL}${client.logo}`}
                                    alt={client.name}
                                />
                            </li>
                        ))}
                    </Row>
                </FadeIn>
            </Container>
        </div>
    );
};

export default SectionClients;
