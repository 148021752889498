export const submitForm = async data => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/`, {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    });
    const responseData = await response.json();

    if (response.ok) {
        return responseData;
    }

    throw responseData.errors;
};
