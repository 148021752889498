import React, { Children, useEffect, useRef } from 'react';
import { TimelineLite, TimelineMax, TweenLite } from 'gsap';

import { bool, oneOfType, func, instanceOf, object, number, string } from 'prop-types';

const Animate = ({ start, timeline, selector, from, to, duration, delay, children }) => {
    const ref = useRef(null);
    useEffect(() => {
        if (window.navigator.userAgent === 'ReactSnap') return;

        const el = selector ? ref.current.querySelector(selector) : ref.current;

        if (from instanceof Function) {
            from(el);
        } else {
            TweenLite.to(el, 0.0, from);
        }
    }, []);

    useEffect(() => {
        if (window.navigator.userAgent === 'ReactSnap') return;

        if (!start) return;

        const el = selector ? ref.current.querySelector(selector) : ref.current;

        if (timeline) {
            if (to instanceof Function) {
                to(el, timeline);
            } else if (delay) {
                timeline.to(el, duration, to, delay);
            } else {
                timeline.to(el, duration, to);
            }
        } else {
            if (to instanceof Function) {
                to(el, TweenLite);
            } else {
                TweenLite.to(el, duration, to);
            }
        }
    }, [start]);

    const OnlyChild = Children.only(children);
    return <OnlyChild.type {...OnlyChild.props} ref={ref} />;
};

Animate.propTypes = {
    start: bool,
    timeline: oneOfType([instanceOf(TimelineLite), instanceOf(TimelineMax)]),
    from: oneOfType([object, func]),
    to: oneOfType([object, func]),
    duration: number,
    delay: oneOfType([number, string]),
    selector: string
};

Animate.defaultProps = {
    start: true,
    timeline: null,
    from: {},
    to: {},
    duration: 0.5,
    delay: null,
    selector: null
};

export default Animate;
