import React from 'react';
import { node } from 'prop-types';

import styles from './a11y.module.scss';

const VisiblyHidden = ({ children }) => <span className={styles.visiblyHidden}>{children}</span>;

VisiblyHidden.propTypes = {
    children: node.isRequired
};

export default VisiblyHidden;
