import React, { Component } from 'react';
import { string, bool } from 'prop-types';

export default class MaskedImage extends Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 348">
                <defs>
                    <clipPath id="masked-image-path">
                        <path d="M165.913043,347.956578 C265.324298,347.956578 360,259.451826 360,159.348756 C360,59.2456855 337.324298,0 237.913043,0 C138.501789,0 0,59.2456855 0,159.348756 C0,259.451826 66.5017885,347.956578 165.913043,347.956578 Z" />
                    </clipPath>
                </defs>
                <rect
                    className="masked-bg"
                    x="0"
                    y="0"
                    width="360"
                    height="348"
                    clipPath="url(#masked-image-path)"
                />
                <image
                    xlinkHref={this.props.image}
                    x="0"
                    y="0"
                    width="360"
                    height="348"
                    className="masked-image"
                    preserveAspectRatio="xMidYMid slice"
                    clipPath={this.props.maskImage ? 'url(#masked-image-path)' : ''}
                />
            </svg>
        );
    }
}

MaskedImage.propTypes = {
    image: string.isRequired,
    maskImage: bool
};

MaskedImage.defaultProps = {
    maskImage: true
};
