import React from 'react';
import { node } from 'prop-types';

import { FadeIn } from '../react-gsap';

const AnimatedPage = ({ children }) => (
    <FadeIn duration={0.4}>
        <div style={{ opacity: 1.0 }}>{children}</div>
    </FadeIn>
);

AnimatedPage.propTypes = {
    children: node.isRequired
};

export default AnimatedPage;
